import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import Dashboard from "@/views/home/dashboard.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  // Home
  {
    path: "/",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: auth.requireAuth,
  },
  // Auth
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/register",
    name: "account-register",
    component: () => import("@/views/account/Register.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/register-success",
    name: "account-register-success",
    component: () => import("@/views/account/RegisterSuccess.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/forgot-password",
    name: "account-forgot-password",
    component: () => import("@/views/account/Forgot.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/reset-password/:email",
    name: "account-reset-password",
    component: () => import("@/views/account/Reset.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/reset-success",
    name: "account-reset-success",
    component: () => import("@/views/account/ResetSuccess.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Profile
  {
    path: "/profile",
    name: "account-profile",
    component: () => import("@/views/account/profile/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/profile-edit",
    name: "account-profile-edit",
    component: () => import("@/views/account/profile/Edit.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Order
  {
    path: "/order-create",
    name: "order-create",
    component: () => import("@/views/order/Create.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/order-list",
    name: "order-list",
    component: () => import("@/views/order/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/order-detail/:order_id",
    name: "order-detail",
    component: () => import("@/views/order/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  // màn sửa đơn hàng
  {
    path: "/order-update/:order_id",
    name: "order-update",
    component: () => import("@/views/order/Update.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Package
  {
    path: "/pack-list",
    name: "pack-list",
    component: () => import("@/views/pack/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack-to-vn",
    name: "pack-to-vn",
    component: () => import("@/views/pack/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack-detail/:pack_id",
    name: "pack-detail",
    component: () => import("@/views/pack/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack-detail/:pack_id",
    name: "pack-detail",
    component: () => import("@/views/pack/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack-delivered",
    name: "pack-delivered",
    component: () => import("@/views/pack/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Delivery
  {
    path: "/delivery-list",
    name: "delivery-list",
    component: () => import("@/views/delivery/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/check-fees",
    name: "check-fees",
    component: () => import("@/views/fees/CheckFees.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/delivery-detail/:delivery_id",
    name: "delivery-detail",
    component: () => import("@/views/delivery/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Đơn ký gửi
  {
    path: "/consignment-create",
    name: "consignment-create",
    component: () => import("@/views/deposit/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/consignment-list",
    name: "consignment-list",
    component: () => import("@/views/deposit/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/consignment-detail/:consignment_id",
    name: "consignment-detail",
    component: () => import("@/views/deposit/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Khiếu nại
  {
    path: "/complain-create/:order_id/",
    name: "complain-create",
    component: () => import("@/views/complain/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/complain-success",
    name: "complain-success",
    component: () => import("@/views/complain/Success.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/complain-list",
    name: "complain-list",
    component: () => import("@/views/complain/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/complain-detail/:complain_id",
    name: "complain-detail",
    component: () => import("@/views/complain/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Other menu
  {
    path: "/other",
    name: "other",
    component: () => import("@/views/other/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Transaction
  {
    path: "/transaction-list",
    name: "transaction-list",
    component: () => import("@/views/transaction/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/transaction-withdraw",
    name: "transaction-withdraw",
    component: () => import("@/views/transaction/Withdraw.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Supplier
  {
    path: "/supplier-list",
    name: "supplier-list",
    component: () => import("@/views/supplier/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Notification
  {
    path: "/notification",
    name: "notification",
    component: () => import("@/views/notification/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // search
  {
    path: "/search-list",
    name: "search-list",
    component: () => import("@/views/search/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/product-detail/:search/:id",
    name: "product-detail",
    component: () => import("@/views/search/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Giỏ hàng
  {
    path: "/cart-create",
    name: "cart-create",
    component: () => import("@/views/cart/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/reset-password-phone/:phone_number",
    name: "account-reset-password-phone",
    component: () => import("@/views/account/ResetPhone.vue"),
    beforeEnter: auth.requireAuthV2,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
