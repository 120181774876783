<template>
  <div class="menu">
    <div class="title_menu_dash">
      <span>Danh mục</span>
    </div>
    <el-row class="menu-item">
      <el-col :span="6">
        <div @click="toCart">
          <div class="box_col_flex" style="background: #F1F7FF">
            <img src="@/static/icon/menu/cart.png" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Giỏ hàng</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toCreateOrder">
          <div class="box_col_flex" style="background: #D9F8FF">
            <img src="@/static/icon/menu/order-create.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Tạo đơn hàng</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toCreateDeposit">
          <div class="box_col_flex" style="background: #E3FFF3">
            <img src="@/static/icon/menu/order-package-create.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Tạo đơn kí gửi</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toOrder">
          <div class="box_col_flex" style="background: #FFE5DF">
            <img src="@/static/icon/menu/order.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Đơn hàng</span>
        </div>
      </el-col>
    </el-row>

    <el-row class="menu-item">
      <el-col :span="6">
        <div @click="toListDeposit">
          <div class="box_col_flex" style="background: #DCEDFF">
            <img src="@/static/icon/menu/order-package.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Đơn ký gửi</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toPackage">
          <div class="box_col_flex" style="background: #E4E0FF">
            <img src="@/static/icon/menu/package.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Kiện hàng</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toWallet">
          <div  class="box_col_flex" style="background: #FFE9CA">
            <img src="@/static/icon/menu/wallet.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Ví điện tử</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toListComplain" class="box_col_flex" style="background: #FFE7E7">
          <img src="@/static/icon/menu/feedback.svg" width="36px" height="36px" />
        </div>
        <div class="mt-2">
          <span>Khiếu nại</span>
        </div>
      </el-col>
    </el-row>

    <el-row class="menu-item">
      <el-col :span="6">
        <div @click="toDelivery">
          <div class="box_col_flex" style="background: #DBEEFF">
            <img src="@/static/icon/menu/ship.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Giao hàng</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toPrice">
          <div class="box_col_flex" style="background: #FFE8E1">
            <img src="@/static/icon/menu/price.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Tra cước</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toSupplier">
          <div class="box_col_flex" style="background: #FFDBEC">
            <img src="@/static/icon/menu/supplier.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Nhà cung cấp</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toAccount">
          <div class="box_col_flex" style="background: #E3FFFE">
            <img src="@/static/icon/menu/account.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Cá nhân</span>
        </div>
      </el-col>
    </el-row>
    <el-row class="menu-item">
      <el-col :span="6">
        <div @click="toReceiveGoods">
          <div  class="box_col_flex" style="background: #E3FFF3">
            <img src="@/static/icon/menu/receive-goods.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Lấy hàng</span>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      title="Lấy hàng"
      :visible.sync="showReceiveGood"
      :close-on-click-modal="true"
      :show-close="false"
      width="90%"
      custom-class="dialog-receive-good"
    >
      <div class="">
        <el-row class="d-flex align-items-center justify-content-center">
          <span style="font-weight: 700; color: #333333; word-break: break-word">
              Khách hàng đưa mã code cho nhân viên kho hàng
          </span>
        </el-row>
        <el-row class="" style="">
          <barcode
            v-bind:value="accountInfo.phone_number"
            width="3"
            height="80"
            text-position="bottom"
            font-size="24"
            :text="accountInfo.code + Date.now()"
          >
          </barcode>
        </el-row>
        <el-row class="mt-2">
          <el-button type="info" style="background-color: #f4f4f4; color: #333333; border: none; width: 130px; border-radius: 16px" @click="showReceiveGood = false">Đóng</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Barcode from 'vue-barcode';
import { mapGetters } from "vuex";

export default {
  name: "DashBoardMenu",
  components: {
    Barcode
  },
  data() {
    return {
      showReceiveGood: false
    };
  },
  computed: {
    ...mapGetters("account", ["logged", "accountInfo"]),
  },
  mounted() {},
  methods: {
    toCart() {
      this.$router.push({ name: 'cart-create' })
    },
    toCreateOrder() {
      this.$router.push({ name: 'order-create' })
    },
    toCreateDeposit() {
      this.$router.push({ name: 'consignment-create' })
    },
    toOrder() {
      this.$router.push({ name: 'order-list' })
    },
    toListDeposit() {
      this.$router.push({ name: 'consignment-list' })
    },
    toPackage() {
      this.$router.push({ name: 'pack-list' })
    },
    toWallet() {
      this.$router.push({ name: 'transaction-list' })
    },
    toListComplain() {
      this.$router.push({ name: 'complain-list' })
    },
    toDelivery() {
      this.$router.push({ name: 'delivery-list' })
    },
    toPrice() {
      this.$router.push({ name: 'check-fees' })
    },
    toSupplier() {
      this.$router.push({ name: 'supplier-list' })
    },
    toAccount() {
      this.$router.push({ name: "account-profile" })
    },
    toReceiveGoods() {
      this.showReceiveGood = true;
    }
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.menu {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px 20px 15px;
  background: #fff;
  border-radius: 10px;
}
.title_menu_dash {
  text-align: left !important;
  font-size: 16px;
  padding: 10px 0 20px 0;
  font-weight: 700;
}

.menu .menu-item {
  width: 100%;
  height: 120px;
}
.box_col_flex {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 6px;
}
  .box_col_flex img {
    /*padding: 10px;
    height: 80px;*/
  }
</style>

<style>
  .vue-barcode-element {
    width: 100% !important;
  }
  .dialog-receive-good {
      border-radius: 8px !important;
  }
</style>
